@import "../../style/custonVariable";
.invoiceContent {
  padding: 15px;
  .printButton {
    text-align: right;
    margin-bottom: 5px;
    .btnAddon {
      i {
        position: relative;
        float: left;
        width: 34px;
        height: 34px;
        margin: -7px -16px;
        margin-right: 5px;
        line-height: 34px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 2px 0 0 2px;
      }
    }
  }
  .panel {
    // padding: 15px;
    .panelBody {
      padding: 10px;
      .logoImg {
        img {
          width: 115px;
          vertical-align: middle;
        }
      }
      h4,
      h5 {
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .well {
        background-color: #edf1f2;
        border-color: #dee5e7;
        min-height: 20px;
        padding: 19px;
        margin-bottom: 20px;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        address {
          margin-bottom: 20px;
          font-style: normal;
          line-height: 1.42857143;
        }
      }
    }
  }
}
//repurchase report styles
.reportContiner {
  padding: 15px;
  .panel {
    .panelBody {
      padding: 15px;
      .dataTalbes {
        padding: 20px;
        position: relative;
        .badge {
          display: inline-block;
          padding: 0.35em 0.5em;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          text-align: center;
          white-space: nowrap;
          vertical-align: baseline;
          border-radius: 3.25rem;
          min-width: 10px;
          text-shadow: 0 1px 0 rgba(252, 213, 213, 0.2);
          &.primaryBadge {
            color: #7266ba;
            background-color: #7266ba54;
          }
        }
      }
    }
  }
}
.btnPrimary {
  background: $color-primary;
  border-color: $color-primary;
  height: 34px;
  &:not(:disabled):not(.disabled):active,
  &:hover,
  &:disabled {
    background: $color-primary;
    border-color: $color-primary;
  }
}
.btnInfo {
  background: $color-info;
  border-color: $color-info;
  height: 34px;
  &:not(:disabled):not(.disabled):active,
  &:hover,
  &:disabled {
    background: $color-info;
    border-color: $color-info;
  }
}

.width100 {
  width: 100%;
}

.exportButton {
  display: inline;
  float: right;
  button {
    background-color: $color-primary;
    height: $input-height;
    border-color: $color-primary;
    border-radius: 0.1875rem;
    margin-right: 0.333em;
    margin-bottom: 0.333em;
    @media (max-width: 600px) {
      margin-right: 8px;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    i {
      text-align: center;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 2px 0 0 2px;
      position: relative;
      float: left;
      width: 30px;
      height: 30px;
      margin: -6px -12px;
      margin-right: -12px;
      margin-right: -12px;
      margin-right: 3px;
      line-height: 30px;
    }
  }
}

.ModalDialog {
  //   position: fixed;
  //   padding: auto;
  //   margin: auto;
  width: 60%;
  height: 60%;
  top: 130px;
  left: 0;
  max-width: 70%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  @media (max-width: 991px) {
    max-width: 90%;
    width: 100%;
  }
  .ModalHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    button {
      margin-left: auto;
      margin-right: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #b9b6b6;
    }
  }
  .ModalBodyHeader {
    font-size: 30px;
    color: #3b5698;
    margin-top: 0px;
  }
}
